import { ZZUploadType } from '@/utils/const.js';
import styles from './flights-panel.module.scss';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Modal } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { deleteFlight } from '@/apis/trips_v4.js';
import notification from '@/utils/notification.jsx';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';

function FlightsPanel({ className }) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const [modal, contextHolder] = Modal.useModal();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  const onUpload = () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, ZZUploadType.flight);
  };

  const getDates = item => {
    const formatDateTime = dateTime => `${dateTime.date.year}-${dateTime.date.month}-${dateTime.date.day} ${dateTime.hour}:${dateTime.minute}`;
    const formatDay = dateTime => dayjs(formatDateTime(dateTime)).format('ddd, MMM D');
    const formatTime = dateTime => dayjs(formatDateTime(dateTime)).format('h:mm A');
    const depart_day = formatDay(item.departure_time);
    const arrive_day = formatDay(item.arrival_time);
    const depart_time = formatTime(item.departure_time);
    const arrive_time = formatTime(item.arrival_time);
    return depart_day === arrive_day
      ? `${depart_day}  •  ${depart_time} - ${arrive_time}`
      : `${depart_day} • ${depart_time}  -  ${arrive_day} • ${arrive_time}`;
  };

  const onConfirm = flightId => {
    modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this flight?',
      onOk: async close => {
        await onDelete(flightId);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onDelete = async flightId => {
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    await deleteFlight({
      session_id: sessionId,
      itinerary_id: itineraryId,
      flight_id: flightId
    });
    notification.success({
      message: 'Flight deleted successfully'
    });
    fetchItineraryAndIntent();
  };

  return (
    <div className={`py-2 overflow-y-auto !px-4 ${className}`}>
      <Button type="primary" onClick={onUpload} className={'mb-4'}>
        Add Flight
      </Button>
      <div className={'flex flex-col'}>
        {itinerary?.flights?.map(item => {
          return (
            <div key={item.id} className={`${styles.item} relative flex flex-col md:flex-row`}>
              <div className={`w-full md:w-0 md:flex-grow flex flex-col ${styles.left} md:border-r pr-7 md:pr-4`}>
                <div className={'flex items-center'}>
                  <span className={'font-medium text-[14px] md:text-[16px]'}>{item.origin.name}</span>
                  <ArrowRightOutlined style={{ fontWeight: 600, fontSize: 18, margin: '0 12px' }} />
                  <span className={'font-medium text-[14px] md:text-[16px]'}>{item.destination.name}</span>
                </div>
                <span className={'text-[13px] mt-1 md:mt-2'}>{getDates(item)}</span>
                {/*<span className={'text-[13px] mt-2'}>$ {item.cost}</span>*/}
                <span className={'text-[13px] mt-1 md:mt-2'}>
                  {item.airline} {item.flight_number}
                </span>
              </div>
              <div className={`flex flex-col w-[30%] md:pl-4 mt-2 md:mt-0`}>
                <span className={styles.label}>confirmation #</span>
                <span className={`${styles.notes} md:mb-3`}>{item.confirmation_number || '--'}</span>
              </div>
              <Button
                type="text"
                className={`${styles.delete} !absolute right-2 top-2 md:!hidden`}
                onClick={() => onConfirm(item.id)}
                icon={<DeleteOutlined />}
              />
            </div>
          );
        })}
      </div>
      {contextHolder}
    </div>
  );
}

export default FlightsPanel;
