export const ZZSegmentedType = {
  ask: 'Ask',
  map: 'Map',
  youtube: 'Youtube',
  media: 'Media',
  places: 'Places',
  picked: 'Picked',
  itinerary: 'Itinerary'
};

export const ZZTripViewMode = {
  detailView: 'Detail View',
  compactView: 'Compact view'
};

export const ZZMapStyle = [
  {
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: 25
      }
    ]
  },
  {
    featureType: 'administrative.country',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#323232'
      },
      {
        lightness: 35
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.province',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.attraction',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.government',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.medical',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.school',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.sports_complex',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    stylers: [
      {
        color: '#e6e6e6'
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e1e1e1'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c8c8c8'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];

export const ZZEmittedEvent = {
  toast: 'toast',
  toastDestroy: 'toastDestroy',
  showPlaceInfo: 'showPlaceInfo',
  triggerLogin: 'triggerLogin',
  loginSuccess: 'loginSuccess',
  showPlayer: 'showPlayer',
  showSource: 'showSource',
  updateAttraction: 'updateAttraction',
  triggerAddPickAttraction: 'triggerAddPickAttraction',
  triggerAddCity: 'triggerAddCity',
  addAIMessage: 'addAIMessage',
  afterPreference: 'afterPreference',
  extractPlaces: 'extractPlaces',
  mapLoaded: 'mapLoaded',
  uploadFlightSuccess: 'uploadFlightSuccess',
  focusAttraction: 'focusAttraction',
  focusTargetDay: 'focusTargetDay',
  editStayCity: 'editStayCity',
  scrollToDay: 'scrollToDay',
  changeTripTab: 'changeTripTab',
  addMyTrip: 'addMyTrip',
  showTrip: 'showTrip',
  showPhotos: 'showPhotos',
  triggerGenerateMessage: 'triggerGenerateMessage'
};

export const ZZSocketTaskType = {
  chat: 'CHAT',
  suggest_itinerary: 'SUGGEST_ITINERARY'
};

export const ZZUploadType = {
  all: 'all',
  hotel: 'hotel',
  flight: 'flight'
};

export const ZZPreferredTools = {
  all: 'All',
  youtube: 'Youtube',
  reddit: 'Reddit'
};

export const ZZGroundingsType = {
  attraction: 'attraction',
  city: 'city',
  hotel: 'hotel',
  restaurant: 'restaurant'
};

export const ZZSourceType = {
  webpage: 'webpage',
  youtube: 'youtube',
  reddit: 'reddit',
  city: 'city',
  attraction: 'attraction',
  hotel: 'hotel',
  restaurant: 'restaurant'
};

export const ZZMessageType = {
  text: 'text',
  place: 'place',
  tool_parameter: 'tool_parameter',
  tool_result: 'tool_result',
  search_source: 'search_source',
  travel_intent: 'travel_intent',
  video: 'video',
  action: 'action',
  daily_itinerary: 'daily_itinerary',
  full_itinerary: 'full_itinerary'
};
