import { Button, Col, Divider, Drawer, Row, Skeleton, Spin, Switch, Tabs } from 'antd';
import styles from './info-drawer.module.scss';
import { useRef, useState, useEffect } from 'react';
import {
  ClockCircleOutlined,
  CloseOutlined,
  DislikeOutlined,
  EnvironmentOutlined,
  ExportOutlined,
  LeftOutlined,
  LikeOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  UpOutlined
} from '@ant-design/icons';
import { nanoid } from 'nanoid';
import GoogleMapReact from 'google-map-react';
import { ZZEmittedEvent, ZZMapStyle } from '@/utils/const.js';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { get, isEmpty } from 'lodash-es';
import { formatTimeRange, formatNumber, getImageUrl, getRatingLevel, getArray } from '@/utils/index.js';
import LogoGoogle from '@/assets/svgs/logo-google.svg';
import IconGrid from '../../../assets/svgs/icon_grid.svg?react';
import IconTicket from '../../../assets/svgs/icon_ticket.svg?react';
import IconPhone from '../../../assets/svgs/icon_phone.svg?react';
import IconInternet from '../../../assets/svgs/icon_internet.svg?react';
import IconPlay from '../../../assets/svgs/icon_play.svg?react';
import LogoTripadvisor from '@/assets/svgs/logo-tripadvisor.svg';
import LogoViator from '@/assets/svgs/logo-viator.svg';
import cache from '@/utils/cache.js';
import { useResponsive } from 'ahooks';
import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { get_videos, getPlaces } from '@/apis/trips_v4.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { logEvent, tagEvent } from '@/utils/ga.js';
import emitter from '@/utils/emitter.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import YoutubeCover from '@/components/commons/YoutubeCover/index.jsx';
import { ZZThemeColor } from '@/utils/config.js';

const zz_tabs = [
  {
    key: 'Overview',
    label: 'Overview'
  },
  {
    key: 'Location',
    label: 'Location'
  }
];

function InfoDrawer(props) {
  const placeId = useGlobalStoreSelectors.use.placeId();

  const [isLoading, setIsLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const [tabs, setTabs] = useState(zz_tabs);
  const [tab, setTab] = useState('Overview');
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [isFold, setIsFold] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const locationRef = useRef(null);
  const overviewRef = useRef(null);
  const [inItineraryDay, setInItineraryDay] = useState(-1);
  const { md } = useResponsive();
  const sessionId = useTripStoreSelectorsV4.use.sessionId();

  useEffect(() => {
    if (props.open) {
      init();
      fetchInfo();
    }
  }, [placeId]);

  useEffect(() => {
    if (place?.description?.length > 300) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [place]);

  const init = () => {
    const { itinerary } = useTripStoreV4.getState();
    const foundDay = itinerary.daily_itineraries.findIndex(day => {
      const idList = day.places.map(p => p.place_id);
      return !!idList.includes(placeId);
    });
    console.log('foundDay-->', foundDay);
    setInItineraryDay(foundDay);
  };

  const onTabChange = key => {
    setTab(key);
    if (key === 'Overview') {
      overviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'Location') {
      locationRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    console.log('map', map, maps);
    const styledMapType = new maps.StyledMapType(ZZMapStyle, { name: 'Styled Map' });
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  };

  const fetchInfo = async () => {
    try {
      onTabChange('Overview');
      setIsLoading(true);
      const results = await getPlaces({
        session_id: sessionId,
        requests: [
          {
            place: {
              place_id: placeId
            }
          }
        ]
      });
      const info = results?.[0];
      if (info?.reviews?.cons) {
        const { cons = {}, pros = {} } = info?.reviews || {};
        setCons(
          Object.keys(cons).map(key => {
            return {
              id: nanoid(),
              label: key,
              text: cons[key]
            };
          })
        );
        const values = Object.keys(pros).map(key => {
          return {
            id: nanoid(),
            label: key,
            text: pros[key]
          };
        });
        console.log('values--->', values);
        setPros(values);
      } else {
        setPros([]);
        setCons([]);
      }
      let reviews = null;
      if (info?.review_sources) {
        reviews = {
          review_sources: info?.review_sources,
          summary: info?.reviews?.summary,
          user_rating: info?.user_rating,
          total_user_reviews: info?.total_user_reviews
        };
        info.reviews = reviews;
        setTabs(zz_tabs);
      } else {
        setTabs(zz_tabs.filter(o => o.key !== 'Reviews'));
      }
      setPlace(info);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onFold = () => {
    setIsFold(prev => !prev);
  };

  const onAfterChange = open => {
    if (!open) {
      setPlace(null);
    }
  };

  return (
    <Drawer
      {...props}
      title={isLoading ? '--' : place?.name}
      mask={false}
      className={`${styles.infoDrawer}`}
      rootClassName={`${styles.infoDrawerRoot} ${md ? styles.pc : ''}`}
      destroyOnClose={true}
      placement={md ? 'left' : 'bottom'}
      width="59%"
      height={'100%'}
      afterOpenChange={onAfterChange}
    >
      {!isLoading ? (
        <>
          <span
            className={'absolute right-3 top-3 bg-white w-9 h-9 rounded-full border cursor-pointer hidden md:flex items-center justify-center z-[10]'}
            onClick={props.onClose}
          >
            <CloseOutlined className={'text-[16px]'} />
          </span>
          <div className={'h-full overflow-y-auto'}>
            <PhotosWall photos={get(place, 'photos', [])} />
            <div className={'px-3 md:px-4'}>
              <Tabs activeKey={tab} items={tabs} onChange={onTabChange} rootClassName={styles.tabs} />
              <div>
                <BasicInfo place={place} inItineraryDay={inItineraryDay} />
                <Divider />
                <PlaceVideos place={place} />
                <Divider />
                {!isEmpty(place?.formatted_hours) && (
                  <>
                    <PlaceHours place={place} />
                    <Divider />
                  </>
                )}
                <div>
                  <div className={'text-[24px] font-medium'}>About</div>
                  <div className={`text-[16px] whitespace-pre-wrap ${isFold ? 'ellipsis-3' : ''}`} ref={overviewRef}>
                    {place?.description}
                  </div>
                  {isTruncated && (
                    <span className={'flex items-center cursor-pointer mt-4'} onClick={onFold}>
                      <span className={'mr-1'}>{isFold ? 'Show more' : 'Show less'}</span>
                      {isFold ? <RightOutlined className={'text-[12px]'} /> : <UpOutlined className={'text-[12px]'} />}
                    </span>
                  )}
                </div>
                <Divider />
                <div className={'flex flex-col mt-5'} ref={locationRef}>
                  <div className={'text-[24px] font-medium'}>Location</div>
                  <div className={'flex items-center text-[16px] mt-3'}>
                    <EnvironmentOutlined className={'text-[20px] mr-2'} />
                    {place?.formatted_address}
                  </div>
                  <div className={'w-full h-[500px] mt-3 rounded-xl overflow-hidden'}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'bfe364ad9b05ea00' }}
                      onGoogleApiLoaded={onGoogleApiLoaded}
                      defaultCenter={{
                        lat: place?.latitude,
                        lng: place?.longitude
                      }}
                      defaultZoom={13}
                    >
                      <AnyReactComponent lat={place?.latitude} lng={place?.longitude} />
                    </GoogleMapReact>
                  </div>
                </div>
                <PlaceReviews place={place} cons={cons} pros={pros} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spin
          indicator={<LoadingOutlined spin style={{ fontSize: 24 }} className={'text-black'} />}
          className={'absolute top-20 left-1/2 -translate-x-1/2'}
        />
      )}
    </Drawer>
  );
}

function PhotosWall({ photos }) {
  const { md } = useResponsive();

  const onShowAllPhotos = () => {
    logEvent({
      name: 'detailedPage_image_click'
    });
    emitter.emit(
      ZZEmittedEvent.showPhotos,
      photos?.map(item => getImageUrl(item.url))
    );
  };

  return (
    <div className="h-64 md:h-[27vw] w-full relative cursor-pointer" onClick={onShowAllPhotos}>
      {photos.length < 5 || !md ? (
        <img src={getImageUrl(get(photos, '0.url', ''), 600)} alt="" className={'h-full w-full object-cover'} />
      ) : (
        <div className={`h-full w-full ${styles.photosWall}`}>
          {photos.slice(0, 5).map((item, index) => {
            return (
              <div key={item.url} className={`${styles['img' + index]} overflow-hidden`}>
                <img src={getImageUrl(item.url, 600)} alt="" className={`h-full w-full object-cover`} />
              </div>
            );
          })}
        </div>
      )}
      <Button icon={<IconGrid />} className={'!bg-white !absolute !right-3 !bottom-3 !font-medium'}>
        Show all photos
      </Button>
    </div>
  );
}

const BasicInfo = ({ place, inItineraryDay }) => {
  const { onAddPlace } = usePlaceV4();
  const toggleIsShowBooking = useGlobalStoreSelectors.use.toggleIsShowBooking();

  const onBooking = () => {
    logEvent({
      name: 'detailedPage_booking_click'
    });
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/ymseCLKk8dcZEJa6iJ4-'
    });
    toggleIsShowBooking(true, {
      place_id: place.place_id,
      booking_url: place?.booking_url
    });
  };

  return (
    <div className={'flex flex-col'}>
      <div className={'flex flex-col items-start md:flex-row md:items-center md:justify-between mb-6'}>
        <span className={'text-[24px] font-medium flex-shrink'}>{place?.name}</span>
        <span className={`flex items-center mt-2 md:mt-0`}>
          <Button
            disabled={inItineraryDay >= 0}
            shape={'round'}
            className={'grayBorder mr-4'}
            onClick={e => onAddPlace(e, place)}
            icon={<PlusOutlined />}
          >
            {inItineraryDay >= 0 ? `Added on day ${inItineraryDay + 1}` : 'Add to trip'}
          </Button>
          {place?.booking_url && (
            <Button shape={'round'} className={'grayBorder'} onClick={onBooking} icon={<IconTicket />}>
              See tickets
            </Button>
          )}
        </span>
      </div>
      <Row className={'flex flex-wrap w-full items-start'} gutter={16}>
        <Col span={12}>
          <div className="w-full flex items-start">
            <IconInternet className={'mr-4'} />
            <div className="flex flex-col w-0 flex-grow">
              <span className={'text-[16px]'}>Website</span>
              <a className={'text-[14px] !text-[#6B7280] ellipsis-2'} href={place?.url} target="_blank">
                {place?.url}
              </a>
            </div>
          </div>
        </Col>
        {place?.phone && (
          <Col span={12}>
            <div className="w-full flex items-start">
              <IconPhone className={'mr-4'} />
              <div className="flex flex-col w-0 flex-grow">
                <span className={'text-[16px]'}>Phone</span>
                <span className={'text-[14px] text-[#6B7280]'}>{place?.phone}</span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

function PlaceHours({ place }) {
  const [is12H, setIs12H] = useState(() => {
    return cache.getIs12HourFormat();
  });

  const onHourChange = checked => {
    cache.setIs12HourFormat(!checked);
    setIs12H(!checked);
  };

  return (
    <div className="w-full flex items-start">
      <ClockCircleOutlined className={'text-[20px] mr-2 mt-0.5'} />
      <div className="flex flex-col w-0 flex-grow">
        <span className={'mb-1 font-medium text-[16px] flex flex-col items-start md:flex-row md:items-center'}>Hours</span>
        <div className={'flex flex-col text-[#555]'}>
          {place?.formatted_hours?.map(item => {
            return (
              <span key={item.day} className={'text-[14px] my-1 text-[#6B7280]'}>
                {item.day}: &nbsp;&nbsp;{item.times.map(item => formatTimeRange(item, is12H)).join(';')}
              </span>
            );
          })}
        </div>
        {!isEmpty(place?.formatted_hours) && (
          <div className={'flex items-center mt-2'}>
            <span className={'font-medium mr-16'}>24-hour time</span>
            <Switch style={{ backgroundColor: !is12H ? ZZThemeColor : '#e5e5e5' }} onChange={onHourChange} checked={!is12H} />
          </div>
        )}
      </div>
    </div>
  );
}

function PlaceVideos({ place }) {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { md } = useResponsive();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      if (place) {
        console.log('place', place);
        setIsLoading(true);
        const { sessionId } = useTripStoreV4.getState();
        const data = await get_videos({
          session_id: sessionId,
          request: {
            place: {
              name: place.name,
              city: place.city,
              country: place.country,
              place_id: place.place_id
            }
            // query: 'travel in SF'
          }
        });
        setVideos(data?.videos || []);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPlay = item => {
    if (isDragging) {
      return;
    }
    logEvent({
      name: 'detailedPage_youtube_click'
    });
    emitter.emit(ZZEmittedEvent.showPlayer, {
      title: item.title,
      url: item.link
    });
  };

  const onPrev = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slidePrev();
    }
  };

  const onNext = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slideNext();
    }
  };

  const onSwiperChange = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  };

  return isLoading ? (
    <div className={'flex items-center w-full'}>
      {getArray(md ? 3 : 2).map(index => {
        return (
          <div className={'flex flex-col w-1/2 md:w-1/3 mr-4'} key={index}>
            <Skeleton.Image active className={'!w-full mb-3 md:!h-36'} />
            <Skeleton active className={'!w-full'} paragraph={{ rows: 3 }} title={false} />
          </div>
        );
      })}
    </div>
  ) : isEmpty(videos) ? null : (
    <div>
      <div className={'text-[24px] font-medium mb-2'}>Videos</div>
      <Swiper
        ref={swiperRef}
        onSlideChange={onSwiperChange}
        spaceBetween={10}
        freeMode={true}
        modules={[Virtual]}
        virtual
        className={styles.videosSwiper}
        slidesPerView={md ? 4.5 : 1.5}
        slidesPerGroup={md ? 4 : 1}
        grabCursor
        onTouchStart={() => setIsDragging(false)}
        onTouchMove={() => setIsDragging(true)}
        onTouchEnd={() => setTimeout(() => setIsDragging(false), 50)}
      >
        {videos.map(item => (
          <SwiperSlide key={item.title} onClick={() => onPlay(item)}>
            <div className={'w-full relative'}>
              <YoutubeCover item={item} className={'w-full h-36 flex-shrink-0'} mask />
              <IconPlay class={'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'} />
            </div>
            <div className={'font-medium mt-2 text-[16px] text-[#374151] ellipsis-3'}>{item.title}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={'mt-3 hidden md:flex items-center justify-center'}>
        <Button size={'small'} disabled={isBeginning} shape="circle" icon={<LeftOutlined />} className="!bg-gray-500] !mx-3" onClick={onPrev} />
        <Button size={'small'} disabled={isEnd} shape="circle" icon={<RightOutlined />} className="!bg-gray-500] !mx-3" onClick={onNext} />
      </div>
    </div>
  );
}

function PlaceReviews({ place, cons, pros }) {
  const [isFoldReviews, setIsFoldReviews] = useState(true);
  const [isReviewsTruncated, setIsReviewsTruncated] = useState(false);

  useEffect(() => {
    if (place?.reviews?.summary?.length > 300) {
      setIsReviewsTruncated(true);
    } else {
      setIsReviewsTruncated(false);
    }
  }, [place]);

  const onFoldReviews = () => {
    setIsFoldReviews(prev => !prev);
  };

  const getLogo = type => {
    const logo = {
      google_places: LogoGoogle,
      tripadvisor: LogoTripadvisor,
      viator: LogoViator
    }[type];
    const sizes = {
      google_places: {
        width: 62,
        height: 16
      },
      tripadvisor: {
        width: 94,
        height: 20
      },
      viator: {
        width: 61,
        height: 20
      }
    };
    return {
      logo,
      size: sizes[type]
    };
  };

  const onOpenUrl = url => {
    window.open(url);
  };

  return (
    (place?.reviews || !isEmpty(cons)) && (
      <div>
        <Divider />
        <div className={'text-[24px] font-medium mb-2'}>Reviews</div>
        {place?.reviews && (
          <>
            <div className={'flex items-center'}>
              <span className={'text-[32px] font-medium mr-2'}>{place?.reviews.user_rating}</span>
              <span className={'flex flex-col'}>
                <span>{getRatingLevel(place?.reviews.user_rating)}</span>
                <span>{formatNumber(place.reviews.total_user_reviews)} reviews</span>
              </span>
            </div>
            <div className={`mt-2 text-[16px] ${isFoldReviews ? 'ellipsis-3' : ''}`}>{place?.reviews?.summary}</div>
            {isReviewsTruncated && (
              <span className={'flex items-center cursor-pointer mt-2'} onClick={onFoldReviews}>
                <span className={'mr-1'}>{isFoldReviews ? 'Show more' : 'Show less'}</span>
                {isFoldReviews ? <RightOutlined className={'text-[12px]'} /> : <UpOutlined className={'text-[12px]'} />}
              </span>
            )}
            <div className={'flex items-center overflow-x-auto mt-3'}>
              {place.reviews.review_sources.map(item => {
                return (
                  <div
                    className={'p-3 border rounded-md w-52 flex-shrink-0 h-[75px] flex-grow-0 mr-3 flex flex-col items-start cursor-pointer'}
                    key={item.provider}
                    onClick={() => onOpenUrl(item.provider_url)}
                  >
                    <div className={'flex items-center mb-2 justify-between w-full'}>
                      <img
                        src={getLogo(item.provider).logo}
                        alt=""
                        width={getLogo(item.provider).size.width}
                        height={getLogo(item.provider).size.height}
                      />
                      <ExportOutlined />
                    </div>
                    <div className="flex items-center">
                      <span className={'font-medium'}>{item.user_rating}/5</span>
                      <span className={'mx-2 text-[#888]'}>·</span>
                      <span className={'text-[#888]'}>{formatNumber(item.total_user_reviews)} reviews</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {!isEmpty(cons) && (
          <Row gutter={24} className={'mt-8'}>
            <Col span={12}>
              <div className={'w-full flex flex-col'}>
                <LikeOutlined className={'text-[24px] font-medium mb-2'} />
                <span className={'font-medium'}>Props</span>
              </div>
              {pros.map(item => (
                <div key={item.id} className={styles.knowItem}>
                  <span className={styles.label}>{item.label}: </span> {item.text}
                </div>
              ))}
            </Col>
            <Col span={12}>
              <div className={'w-full flex flex-col'}>
                <DislikeOutlined className={'text-[24px] font-medium mb-2'} />
                <span className={'font-medium'}>Cons</span>
              </div>
              {cons.map(item => (
                <div key={item.id} className={styles.knowItem}>
                  <span className={styles.label}>{item.label}: </span> {item.text}
                </div>
              ))}
            </Col>
          </Row>
        )}
        <Divider />
      </div>
    )
  );
}

const AnyReactComponent = () => (
  <div className={'w-10 h-10 rounded-full bg-black f-center'}>
    <EnvironmentOutlined className={'text-[20px] text-white'} />
  </div>
);

export default InfoDrawer;
