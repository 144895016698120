import { getImageUrl } from '@/utils/index.js';
import styles from './index.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useResponsive } from 'ahooks';

function ImagesCarousel({ images, max = 8 }) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const { md } = useResponsive();

  const onPrev = e => {
    e.stopPropagation();
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slidePrev();
    }
  };

  const onNext = e => {
    e.stopPropagation();
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slideNext();
    }
  };

  const onSwiperChange = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  };

  return (
    <div className={`relative w-full h-full ${styles.carousel}`}>
      {!isBeginning && (
        <Button
          size={'small'}
          shape="circle"
          icon={<LeftOutlined />}
          className={`!left-3 ${styles.leftBtn} ${!md ? styles.mobile : ''} !cursor-pointer`}
          onClick={onPrev}
        />
      )}
      <Swiper
        className={styles.swiper}
        ref={swiperRef}
        onSlideChange={onSwiperChange}
        pagination={true}
        modules={[Pagination]}
        allowTouchMove={false}
      >
        {images.slice(0, max).map(item => (
          <SwiperSlide key={item.url}>
            <img src={getImageUrl(item.url, 400)} alt={''} className={'w-full h-full object-cover'} />
          </SwiperSlide>
        ))}
      </Swiper>
      {!isEnd && (
        <Button
          size={'small'}
          shape="circle"
          icon={<RightOutlined />}
          className={`!right-3 ${styles.rightBtn} ${!md ? styles.mobile : ''} !cursor-pointer`}
          onClick={onNext}
        />
      )}
    </div>
  );
}

export default ImagesCarousel;
